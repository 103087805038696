<template>
  <v-text-field
    v-model="_value"
    :placeholder="placeholder"
    :name="name"
    v-bind="$attrs"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "InputEmail",
  props: {
    value: {
      type: String,
      default: "",
      required: true
    },
    label: {
      type: String,
      default: function() {
        return this.$t("email");
      },
      required: false
    },
    placeholder: {
      type: String,
      default: function() {
        return "メールアドレス";
      },
      required: false
    },
    name: {
      type: String,
      default: "email",
      required: false
    }
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style scoped></style>
