<template>
  <v-container class="px-0 py-0 bg-color-theme login-page-main fill-height">
    <v-app-bar dense color="primary" class="mb-auto">
      <template>
        <router-link to="/">
          <v-img
            width="175"
            :src="require('@/assets/images/cp-app-logo.svg')"
          ></v-img>
        </router-link>
        <v-spacer></v-spacer>
      </template>
    </v-app-bar>

    <v-container class="my-auto mt-0 pb-10 font-Noto-Sans px-9">
      <validation-observer ref="observer">
        <form @submit.prevent="login">
          <v-row align="center" justify="center">
            <v-col cols="12" class="py-0">
              <h2
                class="font-Noto-Sans primary--text font-weight-600 font-16px mb-5"
              >
                {{ $t("title_login") }}
              </h2>
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required:メールアドレス"
              >
                <input-email
                  class=""
                  v-model="email"
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  placeholder="メールアドレス"
                  solo
                  flat
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required:パスワード"
              >
                <input-password
                  class="password-field"
                  v-model="password"
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  placeholder="パスワード"
                  solo
                  flat
                  :appendToggleEye="true"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-btn
                block
                dense
                type="submit"
                class="font-14px font-Roboto font-weight-600 py-2 primary white--text"
              >
                {{ $t("title_login") }}
              </v-btn>
            </v-col>

            <v-col cols="12" class="text-center pt-0">
              <router-link
                class="text-8e font-16px font-weight-500 font-Noto-Sans"
                :to="{ name: 'forgot_password' }"
              >
                {{ $t("title_forgot_password") }}
              </router-link>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-container>
  </v-container>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import InputPassword from "@/components/ui/InputPassword";
import { mapGetters } from "vuex";
export default {
  name: "Login",
  components: { InputPassword, InputEmail },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus", "user"])
  },
  methods: {
    login() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }

        let email = this.email;
        let password = this.password;
        this.$store.dispatch("AUTH_REQUEST", { email, password }).then(() => {
          this.$store.dispatch("SHOP_GET_ALL").then(() => {

            let isShop = this.$store.getters.getFirstShopObject.id;
            if(isShop == 0){
              this.$router.push({ name: 'add_store' }).catch(() => {});
            }

            if(isShop != 0){
              let authUserData = {
                shop_id: isShop, //Shop Id
                user_id: this.user.id
              };

              this.$store.dispatch("SHOP_USER_AUTH", authUserData).finally(() => {
                this.pageLoading = false;
                let isUserShopStatus = this.$store.getters.userShopStatus;
                if(isUserShopStatus == 0){
                  this.$router.push({ name: 'ConfirmInvitaion' }).catch(() => {});
                }
              });
            }

            if (
              this.$route.query.redirect &&
              this.$route.query.redirect !== ""
            ) {
              this.$router.push(`${this.$route.query.redirect}`);
            } else {
              this.$router.push({ name: "Top" });
            }
          });
          this.$store.dispatch("MASTER_GET_ALL");
          this.$store.dispatch("NOTIFICATION_UNREAD_COUNT_GET");
        });
      });
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
